
import { Options, Vue } from 'vue-class-component'
import theCart from '@/components/Frontend/Cart/index.vue'

@Options({
  components: {
    theCart
  }
})
export default class CartView extends Vue {}
